/**
 * This utility function takes an array of class names (strings) and merges them into a single string.
 * Each class name in the array is separated by a space in the returned string.
 * If the input is not an array, the function returns an empty string.
 *
 * @param {string[]} classNames - An array of class names.
 * @returns {string} A string of class names separated by spaces.
 */
export const classMerge = (classNames: (string | undefined)[]): string => {
  if (!Array.isArray(classNames)) {
    return "";
  }

  return classNames.filter(Boolean).join(" ");
};
