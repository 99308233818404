import type React from "react";
import { HTMLAttributes, MutableRefObject } from "react";

import { text, variants } from "./text.vanilla.css";

import { classMerge } from "@hoken/design-system";

type Variants = {
  [key in keyof typeof variants]?: keyof typeof variants[key];
};

interface TextProps extends HTMLAttributes<HTMLParagraphElement>, Variants {
  as?: keyof HTMLElementTagNameMap;
  children?: React.ReactNode;
  innerRef?: MutableRefObject<HTMLElement | null>;
}

export const Text = ({
  as = "p",
  alignment,
  children,
  className,
  colors,
  cursor,
  decoration,
  font,
  icon,
  innerRef,
  lineHeight,
  marginBottom,
  type,
  weight,
  ...props
}: TextProps) => {
  const Component = as as any;
  const classNameText = text({
    alignment,
    colors,
    cursor,
    decoration,
    font,
    icon,
    lineHeight,
    marginBottom,
    type,
    weight,
  });
  const sanitizedClassName = classMerge([classNameText, className]);
  return (
    <Component ref={innerRef} className={sanitizedClassName} {...props}>
      {children}
    </Component>
  );
};
