import { createTheme } from "@vanilla-extract/css";

export const [themeHoken, vars] = createTheme({
  colors: {
    black: "#000000",
    offBlack: "#151521",
    white: "#ffffff",
    emerald: "#12b981",
    attentionGreen: "#00A538",
    attentionRed: "#E72525",
    redTint: "#FFEDED",
    green100: "#5BFCB8",
    greenTint: "#3FD797",
    primary: "#4033D0",
    primary900: "#0D0A2A",
    primary700: "#1A1453",
    primary600: "#261F7D",
    primary500: "#4033D0",
    primary400: "#7970DE",
    primary300: "#A099E8",
    primary200: "#C6C2F1",
    primary100: "#D9D6F6",
    primary50: "#EDEBF9",
    gray50: "#F3F2F8",
    gray200: "#E6E5EF",
    gray500: "#F3F2F8",
    gray600: "#c0bed4",
    gray650: "#a4a2bd",
    gray700: "#87879e",
    gray750: "#5e5e72",
    gray800: "#3c3c46",
    gray850: "#2E2E38",
    gray900: "#201F2B",
    gray950: "#151521",
    red200: "#E72525",
    transparent: "transparent",
  },
  fonts: {
    family: {
      helvetica: "helvetica neue pro, sans-serif",
      franklin: "libre franklin, sans-serif",
    },
    lineHeights: {
      xsmall: "1",
      small: "1.3",
      medium: "1.5",
      large: "2",
    },
    sizes: {
      "10": "10px",
      "11": "11px",
      "12": "12px",
      "14": "14px",
      "16": "16px",
      "18": "18px",
      "20": "20px",
      "24": "24px",
      "32": "32px",
      "36": "36px",
      "40": "40px",
      "48": "48px",
      "56": "56px",
      // All sizes below assume that the body font size is set to its default (16px)
      xxsmall: "0.75rem", //12px
      xsmall: "0.8125rem", // 13px
      small: ".875rem", // 14px
      medium: "1rem", // 16px
      large: "1.5rem", // 24px
      xlarge: "2rem", // 32px
      xxlarge: "2.5rem", // 40px
      xxxlarge: "3.5rem", // 56px
    },
    weight: {
      thin: "100",
      light: "300",
      regular: "400",
      medium: "500",
      semiBold: "600",
      bold: "700",
      extraBold: "800",
      black: "900",
    },
  },
  space: {
    // t-shirt sizing categorized tokens
    xxsmall: ".125rem",
    xsmall: ".25rem",
    small: ".5rem",
    medium: "1rem",
    large: "1.5rem",
    xlarge: "3rem",
    xxlarge: "4.5rem",
    xxxlarge: "6.5rem",
    // bespoke values (consider removing later)
    "4": "4px",
    "5": "5px",
    "8": "8px",
    "10": "10px",
    "12": "12px",
    "16": "16px",
    "20": "20px",
    "24": "24px",
    "32": "32px",
  },
  containerWidth: {
    small: "320px",
    medium: "768px",
    large: "1024px",
    xlarge: "1280px",
    xxlarge:
      "1368px" /* proposal to change to: 1440px. Note: this need to change across the site */,
  },
  radius: {
    small: "4px",
    medium: "8px",
    full: "100px",
  },
  shadow: {
    card: "0px 0px 20px 0px rgba(27, 29, 90, 0.15)",
  },
});

export const breakpoints = {
  sm: "screen and (min-width: 768px)", // Tablet and small desktop (former bp1, bp2)
  md: "screen and (min-width: 1024px)", // Medium desktop (former bp3)
  lg: "screen and (min-width: 1440px)", // Large dektop
  xl: "screen and (min-width: 1900px)", // Extra large desktop
  smDown: "screen and (max-width: 767px)", // Mobile (use sparsely)
  height: {
    sm: "screen and (min-height: 768px)", // Tablet and small desktop (former bph1)
    xsDown: "screen and (min-height: 640px)", // Very short screens (mainly for desktop)
  },
};
