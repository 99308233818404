import { styled } from "@hoken/core/styles/stitches.config";

export const Box = styled("div", {
  variants: {
    loading: {
      true: {
        loader: {},
      },
    },
    contain: {
      max: {
        maxWidth: "1368px",
        margin: "auto",
      },
    },
    background_color: {
      black: {
        backgroundColor: "$black",
      },
      white: {
        backgroundColor: "$white",
      },
      green: {
        backgroundColor: "$green",
      },
      primary: {
        backgroundColor: "$primary",
      },
    },
    display: {
      flex: {
        display: "flex",
      },
      hidden: {
        display: "none",
      },
    },
    direction: {
      column: {
        flexDirection: "column",
      },
      row: {
        flexDirection: "row",
      },
    },
    justify: {
      center: {
        justifyContent: "center",
      },
    },
    align: {
      center: {
        alignItems: "center",
      },
    },
    gap: {
      6: {
        gap: "6px",
      },
      32: {
        gap: "32px",
      },
    },
    horizontal_padding: {
      0: {
        paddingLeft: "$hoken-core-space-0",
        paddingRight: "$hoken-core-space-0",
      },
      2: {
        paddingLeft: "$hoken-core-space-2",
        paddingRight: "$hoken-core-space-2",
      },
      16: {
        paddingLeft: "$hoken-core-space-16",
        paddingRight: "$hoken-core-space-16",
      },
      32: {
        paddingLeft: "$hoken-core-space-32",
        paddingRight: "$hoken-core-space-32",
      },
      40: {
        paddingLeft: "$hoken-core-space-40",
        paddingRight: "$hoken-core-space-40",
      },
      64: {
        paddingLeft: "$hoken-core-space-64",
        paddingRight: "$hoken-core-space-64",
      },
      80: {
        paddingLeft: "$hoken-core-space-80",
        paddingRight: "$hoken-core-space-80",
      },
    },
    vertical_padding: {
      0: {
        paddingTop: "$hoken-core-space-none",
        paddingBottom: "$hoken-core-space-none",
      },
      16: {
        paddingTop: "$hoken-core-space-16",
        paddingBottom: "$hoken-core-space-16",
      },
      24: {
        paddingTop: "$hoken-core-space-24",
        paddingBottom: "$hoken-core-space-24",
      },
      32: {
        paddingTop: "$hoken-core-space-32",
        paddingBottom: "$hoken-core-space-32",
      },
      40: {
        paddingTop: "$hoken-core-space-40",
        paddingBottom: "$hoken-core-space-40",
      },
      57: {
        paddingTop: "$hoken-core-space-57",
        paddingBottom: "$hoken-core-space-57",
      },
      64: {
        paddingTop: "$hoken-core-space-64",
        paddingBottom: "$hoken-core-space-64",
      },
      80: {
        paddingTop: "$hoken-core-space-80",
        paddingBottom: "$hoken-core-space-80",
      },
      124: {
        paddingTop: "$hoken-core-space-124",
        paddingBottom: "$hoken-core-space-124",
      },
    },
    padding_top: {
      8: {
        paddingTop: "$hoken-core-space-8",
      },
      12: {
        paddingTop: "$hoken-core-space-12",
      },
      22: {
        paddingTop: "$hoken-core-space-22",
      },
      24: {
        paddingTop: "$hoken-core-space-24",
      },
      32: {
        paddingTop: "$hoken-core-space-32",
      },
      40: {
        paddingTop: "$hoken-core-space-40",
      },
      64: {
        paddingTop: "$hoken-core-space-64",
      },
      124: {
        paddingTop: "$hoken-core-space-124",
      },
    },
    padding_left: {
      27: {
        paddingLeft: "27px",
      },
      32: {
        paddingLeft: "32px",
      },
    },
    padding_right: {
      32: {
        paddingRight: "32px",
      },
      124: {
        paddingRight: "124px",
      },
    },
    horizontal_margin: {
      0: {
        marginLeft: "$hoken-core-space-none",
        marginRight: "$hoken-core-space-none",
      },
      24: {
        marginLeft: "$hoken-core-space-24",
        marginRight: "$hoken-core-space-24",
      },
      32: {
        marginLeft: "$hoken-core-space-32",
        marginRight: "$hoken-core-space-32",
      },
      36: {
        marginLeft: "$hoken-core-space-36",
        marginRight: "$hoken-core-space-36",
      },
    },
    vertical_margin: {
      0: {
        marginTop: "$hoken-core-space-none",
        marginBottom: "$hoken-core-space-none",
      },
      24: {
        marginTop: "$hoken-core-space-24",
        marginBottom: "$hoken-core-space-24",
      },
      32: {
        marginTop: "$hoken-core-space-32",
        marginBottom: "$hoken-core-space-32",
      },
      36: {
        marginTop: "$hoken-core-space-36",
        marginBottom: "$hoken-core-space-36",
      },
    },
    margin_top: {
      8: {
        marginTop: "$hoken-core-space-8",
      },
      24: {
        marginTop: "$hoken-core-space-24",
      },
      32: {
        marginTop: "$hoken-core-space-32",
      },
      36: {
        marginTop: "$hoken-core-space-36",
      },
      40: {
        marginTop: "$hoken-core-space-40",
      },
      64: {
        marginTop: "$hoken-core-space-64",
      },
      124: {
        marginTop: "$hoken-core-space-124",
      },
    },
    margin_bottom: {
      16: {
        marginBottom: "$hoken-core-space-16",
      },
      32: {
        marginBottom: "$hoken-core-space-32",
      },
      40: {
        marginBottom: "$hoken-core-space-40",
      },
      124: {
        marginBottom: "$hoken-core-space-124",
      },
    },
  },
});
